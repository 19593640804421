import React from 'react';

const LatestVideo: React.FC = () => {
  return (
    <div>
      <h2>Latest Video</h2>
      <iframe
        width="100%"
        height="200"
        src="https://www.youtube.com/embed/example"
        title="Latest Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default LatestVideo;

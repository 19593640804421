import React from 'react';
import { useNavigate } from 'react-router-dom';
import AboutMeSummary from '../AboutMeSummary';
import ProjectsPreview from '../ProjectsPreview';
import BlogPostsPreview from '../BlogPostsPreview';
import LatestVideo from '../LatestVideo';
import '../Css/MainContent.css';
import SpecialSection from '../SpecialSection';

const MainContent: React.FC = () => {
    const navigate= useNavigate();
    const handleAboutMeClick = () => {
        navigate('/about-me');
      };
    return (
      <div className="main-content">
        <div className="box aboutMeBox" onClick={handleAboutMeClick}>
          <AboutMeSummary />
        </div>
        <div className="box ProjectsBox">
          <ProjectsPreview />
        </div>
        <div className="box BlogPostsBox">
          <BlogPostsPreview />
        </div>
        <div className="box LatestVideosBox">
          <LatestVideo />
        </div>
        <div className="specialBox">
          <SpecialSection />
        </div>
      </div>
    );
  };
  
  export default MainContent;
import React from 'react';
import './Css/SpecialSection.css'; // Stil dosyası, düzenlemeler için

const SpecialSection: React.FC = () => {
  return (
    <div>
      <h2>Special Content</h2>
      <p>
      Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
        Technologies will be located here
      </p>
      <button className="special-button">Learn More</button>
    </div>
  );
};

export default SpecialSection;

import React from 'react';

const BlogPostsPreview: React.FC = () => {
  return (
    <div>
      <h2>Blog Posts</h2>
      <ul>
        <li>Coming soon</li>
      </ul>
    </div>
  );
};

export default BlogPostsPreview;

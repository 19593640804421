import React from 'react';

const AboutMePage: React.FC = () => {
  return (
    <div>
      <h1>About Me</h1>
      <p>I'm a Computer Engineer with a strong background in backend development and scalable solutions. With expertise in .NET Core, microservices, and database optimization, I enjoy crafting efficient and clean systems. My passion lies in learning new technologies and solving complex problems, which has driven my journey in software engineering. Beyond work, I value collaboration and continuous improvement, always aiming to deliver impactful solutions.</p>
    </div>
  );
};

export default AboutMePage;
import React from 'react';
import { Link } from 'react-router-dom';
import './Css/Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header-logo">
        <h1>My Blog</h1>
      </div>
      <nav className="header-nav">
        <ul>
        <li><Link to="/about-me">About Me</Link></li>
          <li><a href="#blog">Blog Posts</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><Link to="/my-videos">Videos</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
import React from 'react';

const MyVideosPage: React.FC = () => {
  return (
    <div>
      <h1>My Videos page</h1>
      <p>My videos will locate here</p>
    </div>
  );
};

export default MyVideosPage;
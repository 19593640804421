import React from 'react';

const ProjectsPreview: React.FC = () => {
  return (
    <div>
      <h2>Projects</h2>
      <ul>
        <li>
            <a href="https://github.com/UfukAkyuzYildirim/SmartHomeHub" target="_blank" rel="noopener noreferrer">
                Smart Home Hub project
            </a>
        </li>
      </ul>
    </div>
  );
};

export default ProjectsPreview;

import React from 'react';
import profilePhoto from '../Images/Profile.png';
import './Css/AboutMe.css';

const AboutMe: React.FC = () => {
  return (
    <div className="about-me">
      <div className="profile-photo-container">
        <img src={profilePhoto} alt="Profile" className="profile-photo" />
      </div>
      <div className="about-text">
        <h1>Welcome to Akyüz's Blog – A Developer's Journey</h1>
        <p>
          Backend developer passionate about .NET. Sharing my journey in tech, insights on coding, and problem-solving experiences.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import AboutMe from './Components/AboutMe';
import MainContent from './Components/Pages/MainContent';
import AboutMePage from './Components/Pages/AboutMePage';
import MyVideosPage from './Components/Pages/VideosPage';

function App() {
  return (
    <BrowserRouter>
    <Header/>
    <AboutMe/>
    <Routes>
      <Route path="/" element={<MainContent/>} />
      <Route path="/about-me" element={<AboutMePage/>} />
      <Route path="/my-videos" element={<MyVideosPage/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
